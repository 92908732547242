import { object_to_s } from 'base'

export type LogLevel = 'debug' | 'info' | 'warn' | 'error'
export interface LogMessage { level: LogLevel, message: string, data?: object, module?: string, id?: string }
export interface LogEmitter { emit(message: LogMessage): void }

export class Log {
  readonly _module?: string; readonly _id?: string
  static emitters: LogEmitter[] = []

  constructor(scope?: string | object, id?: string | number) {
    if (scope !== undefined) {
      if (string7(scope)) {
        this._module = scope
      } else {
        this._module = scope.constructor.name
        this._id    = (scope as any).id?.to_s()
      }
    }
    if (id !== undefined) this._id = id.to_s()
  }

  id(id: string | number): Log { return new Log(this._module, id) }

  emit(level: LogLevel, message: string, data?: object) { Log.emit({
    level, module: this._module || this.constructor.name, ...(this._id ? { id: this._id } : {}),
    message: to_s(message), data
  }) }

  info(message: string, data?: object) { this.emit('info', message, data) }
  debug(message: string, data?: object) { this.emit('debug', message, data) }
  warn(message: string, data?: object) { this.emit('warn', message, data) }
  error(message: string, data?: object) { this.emit('error', message, data) }

  static emit(message: LogMessage) { Log.emitters.each(e => e.emit(message)) }

  static info(message: string, data?: object) { this.emit({ level: 'info', message, data }) }
  static debug(message: string, data?: object) { this.emit({ level: 'debug', message, data }) }
  static warn(message: string, data?: object) { this.emit({ level: 'warn', message, data }) }
  static error(message: string, data?: object) { this.emit({ level: 'error', message, data }) }
}

// TerminalEmitter ---------------------------------------------------------------------------------
export class TerminalEmitter implements LogEmitter {
  public static disabled: Set<string> = new Set()

  constructor() {
    TerminalEmitter.disabled = new Set((ENV['disable_logs'] || '').divide(/\s*,\s*/).map(e => e.trim()))
  }

  emit(message: LogMessage) {
    if (TerminalEmitter.disabled.has(message.module || '') || TerminalEmitter.disabled.has(message.level)) return

    const fmodule = ((message.module || '').downcase().ljust(4).get(0, 3))
    const fid     = ((message.id || '').ljust(7).get(0, 6))

    let main = `${fmodule} | ${fid} ${message.message}`
    let line: [string, string][]
    switch (message.level) {
      case 'debug': line = [[`  ${main}`, 'color: grey;']];   break
      case 'info':  line = [[`  ${main}`, 'color: black;']];  break
      case 'warn':  line = [[`W ${main}`, 'color: yellow;']]; break
      case 'error': line = [[`E ${main}`, 'color: red;']];    break
      default:      raise(`unknown log level: ${message.level}`)
    }
    const indent = 17

    const data = message.data
    if (data !== undefined) {
      if (data instanceof Error) {
        console.flog(...line)
        console.flog([`${data.message || 'No error message'}`.indent(indent), 'color: red;'])
        console.flog([`${data.stack}`.indent(indent), 'color: grey;'])
      } else if (record7(data)) {
        if (size(data) > 0) {
          const fdata = object_to_s(data)
          console.flog(...line, [` ${fdata}`, 'color: grey;'])
        } else {
          console.flog(...line)
        }
      } else {
        raise(`invalid log data: ${data}`)
      }
    } else {
      console.flog(...line)
    }
  }
}

Log.emitters.add(new TerminalEmitter())


// Test --------------------------------------------------------------------------------------------
if (import.meta.main) {
  const log = new Log('Finance').id('MSFT')

  log.info('getting prices in USD')
  log.info('getting prices in USD', { price: 100 })
  log.warn('no response')

  try { raise('some error') } catch (e) {
    log.error('cant get price', e as Error)
  }
}