import 'base'
import { Log } from 'ext/log'

const log = new Log('executor')

export interface Executor {
  open_doc(path: string, line: number): Promise<void>
}

export interface RCall { fn: string, args: any[] }

export class RemoteExecutor implements Executor {
  constructor (readonly host: string | nil = nil, readonly mount_path = '/_executor') {}

  protected async rcall(fn: string, ...args: unknown[]): Promise<any> {
    const rcall: RCall = { fn, args }
    const raw_res = await fetch(`${this.host ? this.host : ''}${this.mount_path}`,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },  body: JSON.encode(rcall)}
    )
    const res: Result<unknown> = await raw_res.json()
    if (res.error7) raise(res.error)
    return res.result
  }

  async open_doc(path: string, line: number): Promise<void> {
    log.info('open_doc', { path, line })
    return this.rcall('open_doc', ...arguments)
  }
}