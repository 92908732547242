import 'base'
import { h, render } from 'preact'
import { Message } from './palette'
import { DocView } from './views/DocView'
import { RemoteExecutor } from './serve/executor'
import { TerminalEmitter } from 'ext/log'
import { restore_scroll } from 'ext/browser'

// Config ------------------------------------------------------------------------------------------
TerminalEmitter.disabled.add('store')

// View --------------------------------------------------------------------------------------------
export interface ViewConfig {
}

export interface ViewArgs { path?: string, open_doc_online_path?: string }

export async function view(args?: ViewArgs) {
  if (!args) {
    const args_el = document.getElementById('view_args')
    if (args_el) args = from_s(args_el.innerHTML)
  }
  if (!args) raise('No args')

  const executor = new RemoteExecutor()
  if (args.open_doc_online_path) {
    executor.open_doc = async (path, line) => {
      window.open(`${args?.open_doc_online_path}${path}#L${line}`, '_blank')?.focus()
    }
  }

  const config: ViewConfig = {}

  const path = args.path || get_path_from_url()

  function render_error(message: string) {
    render(<Message type="error" top text={message}/>, document.body)
  }
  if (!path) return render_error('No path')
  // if (entry.type != 'file' || !entry.npath.end_with7('ft')) return render_error('Entry type not supported')

  render(<DocView path={path} config={config} executor={executor}/>, document.body)
  restore_scroll()
}

// Helpers -----------------------------------------------------------------------------------------
function get_path_from_url(): string | nil {
  const url_params = new URLSearchParams(window.location.search)
  return url_params.get('path') ?? nil
}