import 'base'
import { ensure_loaded } from 'ext/browser'
import { get_base_name, get_base_path, get_parent_dir, join_paths } from 'ext/fs'
import { RemoteStore, Store } from 'ext/store'
import { line_n } from 'htext/lexer/helpers'
import { pos7 } from 'htext/lexer/schema'
import { Doc, HtmlContext, ResolveContext } from 'keep/doc'
import { parse_htext } from 'keep/doc/htext'
import { App, BlockLayout, ChapterHeader, IconButton, Message, Progress, SectionHeader } from 'keep/palette'
import { Executor } from 'keep/serve/executor'
import { ViewConfig } from 'keep/view'
import { h, Component, VNode } from "preact"

interface DocViewState { resolved?: Result<{ doc: Doc, source: string }> }
interface DocViewProps { path: string, config: ViewConfig, executor: Executor }
export class DocView extends Component<DocViewProps>{
  state: DocViewState = {}
  store: Store

  constructor(props: DocViewProps) {
    super(props)
    this.store = new RemoteStore()
  }

  async resolve() {
    try {
      const source = await this.store.read_file(this.props.path)
      const doc = await parse_htext(source, get_base_name(this.props.path), this.props.path)

      const rctx = this.resolve_context()
      await doc.resolve(rctx)
      await ensure_loaded(...rctx.ensure_assets)
      doc.errors.add(...rctx.errors)

      this.setState({ resolved: to_success({ doc, source }) })
    } catch (e) {
      this.setState({ resolved: to_error(error_message(e)) })
    }
  }

  resolve_context(): ResolveContext {
    const base_path = get_base_path(this.props.path), parent_path = get_parent_dir(this.props.path)
    function resolve_relative_path(path: string): string {
      if (path == 'this' || path.start_with7('this/')) path = path.replace(/^this/, base_path)
      if (!path.start_with7('/'))   path = join_paths(parent_path ?? '/', path)
      return path
    }
    return {
      errors: [], ensure_assets: [],
      resolve_path: async path => this.store.find(resolve_relative_path(path)),
      read_dir: async path => this.store.read_dir(resolve_relative_path(path))
    }
  }

  html_context(): HtmlContext {
    return {
      link_path(path) {
        if (path.end_with7('.ht')) return path.replace(/\.ht$/, '')
        return path
      },
      tag_path:   tag => '/tag/' + tag, // LODO
      asset_path: path => path,
    }
  }

  componentDidMount() { if (!this.state.resolved) this.resolve() }

  render() {
    const state = this.state
    if (state.resolved) {
      if (state.resolved.error7) {
        return <App>
          <Message type='error' text={state.resolved.error}/>
        </App>
      } else {
        const { doc, source } = state.resolved.result
        const doc_path = string7(doc.loc) ? doc.loc : nil

        const controls = ({ loc }: { loc?: unknown }): VNode[] | nil => {
          if (!(doc_path && loc && pos7(loc))) return
          const open_doc = () => this.props.executor.open_doc(doc_path, line_n(source, loc[0]))
          return [<IconButton icon='edit' onClick={open_doc}/>]
        }

        const hctx = this.html_context()

        // Rendering blocks
        const blocks: VNode[] = []
        for (let si = 0; si < doc.sections.length; si++) {
          const section = doc.sections[si]
          if (section.title || !section.tags.empty7()) { blocks.add(
            <BlockLayout id={section.id || `section-${si}`} name='pblock' hover controls={controls(section)}>
              <SectionHeader title={section.title || 'Section'} tags={section.tags}/>
            </BlockLayout>
          ) }
          for (let ci = 0; ci < section.chapters.length; ci++) {
            const chapter = section.chapters[ci]
            if (chapter.title || !chapter.tags.empty7()) { blocks.add(
              <BlockLayout id={chapter.id || `chapter-${si}-${ci}`} name='pblock' hover controls={controls(chapter)}>
                <ChapterHeader title={chapter.title || 'Chapter'} tags={section.tags}/>
              </BlockLayout>
            ) }
            for (let bi = 0; bi < chapter.blocks.length; bi++) {
              const block = chapter.blocks[bi], html = block.to_html(hctx)
              const id = block.id || `block-${si}-${ci}-${bi}`
              blocks.add(
                <BlockLayout id={id} name='pblock' tags={block.tags} hover controls={controls(block)}>
                  {string7(html) ?
                    <div class="htext" dangerouslySetInnerHTML={{ __html: html }}/> :
                    <div class="htext">{html}</div>
                  }
                </BlockLayout>
              )
            }
          }
        }

        return <App title={doc.title} tags={doc.tags} title_hover title_controls={controls({ loc: [0, 0]})}>
          {doc.errors.length > 0 && <BlockLayout name='pblock-errors'>
            {doc.errors.map(e =>
              <Message text={`${e.error} (${e.context})`} type='error'/>
            )}
          </BlockLayout>}
          {blocks}
        </App>
      }

    } else {
      return <App>
        <Progress>Loading...</Progress>
      </App>
    }
  }
}