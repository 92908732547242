import { fs, get_base_name, get_extension, get_name, get_parent_dir, join_paths } from "./fs"
import { Log } from "./log"
import { parsers } from "./parsers"

export type EntryHead = FileHead | DirHead
export interface FileHead { type: 'file', path: string, tags: string[] }
export interface DirHead  { type: 'dir', path: string }

export interface Meta { [name_or_basename: string]: string[] }

export interface Store {
  find(path: string): Promise<EntryHead | nil>
  find_file(path: string): Promise<FileHead | nil>
  find_dir(path: string): Promise<DirHead | nil>
  read_file(path: string): Promise<string>
  /** `recursive` false by default */
  read_dir(path: string, recursive?: boolean): Promise<EntryHead[]>
}

// RemoteStore -------------------------------------------------------------------------------------
interface RCall { fn: string, args: any[], resolve: (v: any) => void, reject: (e: any) => void }

export class RemoteStore implements Store {
  protected rcalls: RCall[] = []
  protected rcalls_scheduled = false
  constructor (readonly host: string | nil = nil, readonly mount_path = '/_store_service') {}

  protected rcall(fn: string, ...args: unknown[]): Promise<any> {
    const { promise, resolve, reject } = Promise.withResolvers<EntryHead | nil>()
    this.rcalls.push({ fn, args, resolve, reject })
    if (!this.rcalls_scheduled) {
      this.rcalls_scheduled = true
      next_tick(() => this.batch_rcall())
    }
    return promise
  }
  protected async batch_rcall() {
    const rcalls = this.rcalls; this.rcalls = []; this.rcalls_scheduled = false
    let ress: Result<unknown>[]
    try {
      const raw_res = await fetch(`${this.host ? this.host : ''}${this.mount_path}`,
        { method: 'POST', headers: { 'Content-Type': 'application/json' },  body: JSON.encode(rcalls)}
      )
      ress = await raw_res.json()
      assert(array7(ress))
    } catch (e) {
      rcalls.each(r => r.reject(e))
      return
    }

    ress.each((res, i) =>
      res.error7 ? rcalls[i].reject(new Error(res.error)) : rcalls[i].resolve(res.result)
    )
  }

  async find(path: string): Promise<EntryHead | nil> {
    log.info('find', { path })
    return this.rcall('find', ...arguments)
  }

  async find_file(path: string): Promise<FileHead | nil> {
    log.info('find_file', { path })
    return this.rcall('find_file', ...arguments)
  }

  async find_dir(path: string): Promise<DirHead | nil> {
    log.info('find_dir', { path })
    return this.rcall('find_dir', ...arguments)
  }

  async read_file(path: string): Promise<string> {
    log.info('read_dir', { path })
    return this.rcall('read_file', ...arguments)
  }

  async read_dir(path: string): Promise<EntryHead[]> {
    log.info('read_dir', { path })
    return this.rcall('read_dir', ...arguments)
  }
}

export type StoreService = (req: Request, url: URL) => Promise<Response> | nil
export function store_service(store: Store, mount_path = '/_store_service'): StoreService {
  async function handle(req: Request) {
    const rcalls: RCall[] = await req.json(), ress: Result<unknown>[] = []
    assert(array7(rcalls))
    for (const { fn, args } of rcalls) {
      try {
        const r = await (store as any)[fn](...args)
        ress.add(to_success(r))
      } catch (e) {
        ress.add(to_error(get_error_message(e)))
      }
    }
    return new Response(JSON.encode(ress), { headers: { 'Content-Type': 'application/json' } })
  }

  return function(req: Request, url: URL) {
    return (req.method == 'POST' && url.pathname == mount_path) ? handle(req) : nil
  }
}

const log = new Log('store')

// FSStore -----------------------------------------------------------------------------------------
export function default_ignore() { return new Set(['.git', '.DS_Store']) }
export class FSStore implements Store {
  private cache_timer: Timer

  constructor (
    readonly dirs: string[],
    readonly ignore: Set<string> = default_ignore(),
    readonly cache_ttl_ms = 1000
  ) {
    this.cache_timer = setInterval(() => this.meta_files_cache = {}, this.cache_ttl_ms)
  }

  destroy() { clearInterval(this.cache_timer) }

  async find(path: string): Promise<EntryHead | nil> {
    log.info('find', { path })
    return path.end_with7('/') ? this.find_dir(path) : this.find_file(path)
  }

  async find_dir(path: string): Promise<DirHead | nil> {
    log.info('find_dir', { path })
    assert(path.start_with7('/') && path.end_with7('/'))
    const fs_path = path.length > 1 ? path.replace(/\/+$/, '') : path
    for await (const dir of this.dirs) {
      const try_path = dir + fs_path, type = await fs.info(try_path)
      if (type == 'dir') return { type, path }
    }
    return nil
  }

  async find_file(path: string): Promise<FileHead | nil> {
    log.info('find_file', { path })
    return (await this.find_file_with_fs_path(path))?.entry
  }

  async find_file_with_fs_path(path: string): Promise<{ entry: FileHead, fs_path: string } | nil> {
    log.info('find_file_with_fs_path', { path })
    assert(path.start_with7('/') && !path.end_with7('/'))

    // Searching for exact match
    for await (const dir of this.dirs) {
      const try_path = dir + path
      const type = await fs.info(try_path)
      if (type == 'file') {
        const fs_path = try_path
        return { entry: { type, path, ...(await this.read_meta(fs_path)) }, fs_path }
      }
    }

    // Searching without extension
    if (!get_extension(path)) {
      const parent_dir = get_parent_dir(path); const basename = get_base_name(path)
      for (const dir of this.dirs) {
        const fs_try_dir = dir + parent_dir
        if ((await fs.info(fs_try_dir)) != 'dir') continue
        const entries = await fs.read_dir(fs_try_dir)
        for (const { name, type } of entries) {
          if (get_base_name(name) == basename && type == 'file') {
            const fs_path = join_paths(fs_try_dir, name), meta = await this.read_meta(fs_path)
            return { entry: { type, path: join_paths(parent_dir || '/', name), ...meta }, fs_path }
          }
        }
      }
    }

    return nil
  }

  async read_file(path: string): Promise<string> {
    log.info('read_file', { path })
    const found = await this.find_file_with_fs_path(path)
    if (!found) raise(`File not found: ${path}`)
    return fs.read(found.fs_path)
  }

  /** If dir doesn't exist returns empty response */
  async read_dir_impl(path: string): Promise<EntryHead[]> {
    assert(path.start_with7('/') && path.end_with7('/'))
    const fs_path_part = path.length > 1 ? path.replace(/\/+$/, '') : path

    const r: EntryHead[] = []; let at_least_one_found = false
    for await (const dir of this.dirs) {
      const try_path = dir + fs_path_part
      const type = await fs.info(try_path)
      if (type == 'dir') {
        at_least_one_found = true
        const entries = await fs.read_dir(try_path)
        for (const { name, type } of entries) {
          if (this.ignore.has(name)) continue
          const path = join_paths(fs_path_part, name), fs_path = join_paths(try_path, name)
          if (type == 'file') r.push({ type, path, ...(await this.read_meta(fs_path)) })
          else                r.push({ type, path })
        }
      }
    }
    if (!at_least_one_found) raise(`Dir not found: ${path}`)
    return r.order(({ path }) => path)
  }

  async read_dir(path: string, recursive = false): Promise<EntryHead[]> {
    log.info('read_file', { path, recursive })
    if (recursive) {
      const r: EntryHead[] = []
      const add = async (entry: EntryHead) => {
        r.add(entry)
        if (entry.type == 'dir') for (const e of await this.read_dir(entry.path + '/')) await add(e)
      }
      for (const dir of await this.read_dir(path)) await add(dir)
      return r.order(({ path }) => path)
    } else {
      return this.read_dir_impl(path)
    }
  }

  protected async read_meta(fs_path: string): Promise<{ tags: string[] }> {
    assert(fs_path.start_with7('/'))

    // File tags
    const tags: string[] = [], file_parent_dir = ensure(get_parent_dir(fs_path))
    const file_meta = await this.meta_file(file_parent_dir)
    if (file_meta) {
      const file_name = get_name(fs_path), file_base_name = get_base_name(file_name)
      tags.add(...(file_meta[file_name] || []))
      if (file_base_name != file_name) tags.add(...(file_meta[file_base_name] || []))
    }

    // Tags inherited from parents
    let current_dir: string | nil = file_parent_dir
    while (current_dir) {
      const parent_dir = get_parent_dir(current_dir)
      if (parent_dir) {
        const dir_meta = await this.meta_file(parent_dir)
        if (dir_meta) {
          const dir_tags = dir_meta[`${get_name(current_dir)}/`]
          if (dir_tags) for (const tag of dir_tags) if (!tags.includes(tag)) tags.add(tag)
        }
      }
      current_dir = get_parent_dir(current_dir)
    }

    return { tags }
  }

  meta_files_cache: { [fs_dir_path: string]: { value: Meta | nil } } = {}
  protected async meta_file(fs_dir_path: string): Promise<Meta | nil> {
    if (fs_dir_path == '/') return { tags: [] }
    if (!(fs_dir_path in this.meta_files_cache)) {
      assert(fs_dir_path.start_with7('/'))
      const cache: { value: Meta | nil } = { value: nil }
      let meta_s: string | nil
      try { meta_s = await fs.read(`${fs_dir_path}/meta`) } catch (e) {}
      if (meta_s) cache.value = parsers.yaml(meta_s)
      this.meta_files_cache[fs_dir_path] = cache
    }
    return this.meta_files_cache[fs_dir_path].value
  }
}

if (import.meta.main) {
  const store = new FSStore(['.'])
  p(await store.find_file('/play'))
}